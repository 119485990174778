import React from "react"

import view from "./imageparagraph.module.scss"
import { Link } from "gatsby";
function ImageParagraphComponent({ data }) {
  const { imageurl, imagelocation } = data
  const { title, description, paragraph } = data
  const { btntext, btnlink } = data

  const ComponentID = btntext.replace(/\s+/g, '-').toLowerCase();

  const styleDispatch = {
    top: { "flex-direction": "column" },
    bottom: { "flex-direction": "column-reverse" },
    right: { "flex-direction": "row-reverse" },
    left: { "flex-direction": "row" }
  }

  const styleDispatcher = (orientation) => {
    if (Object.keys(styleDispatch).indexOf(orientation) > -1) {
      return styleDispatch[orientation]
    }
  }

  const renderImage = () => {
    return <img className={view["image"]} src={imageurl} />
  }

  const renderParagraph = () => {
    return (
      <>
        {title != '' ? <p className={view["image-title"]}>{title}</p> : <span></span>}
        {description != '' ? <p className={view["image-description"]}>{description}</p> : <span></span>}
        {paragraph != '' ? <div  dangerouslySetInnerHTML={{__html: paragraph}} /> : <span></span>}
        {(btntext != '' && btnlink != '') ? <Link className={view["apply-btn"]} to={btnlink}>{btntext}</Link> : <span></span>}
      </>
    )
  }

  return (
    <div id={ComponentID} className={view["img-para-wrap"]}>
      <div className={view["content-wrap"]} style={styleDispatcher(imagelocation)}>
        <div className={view["img-container"]}>{renderImage()}</div>
        <div className={view["p-container"]}>{renderParagraph()}</div>
      </div>
    </div>
  )
}

ImageParagraphComponent.defaultProps = {
  data: {
    imageurl: 'http://d2ztewo589hjnx.cloudfront.net/02df3920fc0f76cbfd04aa6c049f1865.jpg',
    imagelocation: 'right',
    title: 'Looking For',
    description: 'incredible RV financing?',
    paragraph: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, ',
    btntext: 'Apply for financing',
    btnlink: ' https://gateway.appone.net/onlineapp/CENTURYRV'
  }
}

export default ImageParagraphComponent